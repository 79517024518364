const NumericOnlyDirective = {
  name: "numericOnly",
  bind(el, binding) {
    if (
      !Object.prototype.hasOwnProperty.call(binding, "value") ||
      binding.value
    ) {
      let element = el;
      if (el.tagName !== "INPUT") {
        element = el.querySelector("input") || el.querySelector("textarea");
      }
      if (element) {
        element.addEventListener("keydown", (e) => {
          let allowKeys = [46, 8, 9, 27, 13, 110, 188, 189, 190];

          let allowChars = ["-"];
          if (
            allowKeys.includes(e.keyCode) ||
            //Allow minus sign
            allowChars.includes(e.key) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
            ((e.keyCode == 65 || e.keyCode == 86 || e.keyCode == 67) &&
              (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)
          ) {
            // let it happen, don't do anything
            return;
          }
          // Ensure that it is a number and stop the keypress
          if (
            (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
            (e.keyCode < 96 || e.keyCode > 105)
          ) {
            e.preventDefault();
          }
        });
      }
    }
  },
};
export { NumericOnlyDirective };
