var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"question column",class:_vm.width,attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"question-text"},[(_vm.showQuestionCode)?_c('p',{staticClass:"question-text__code"},[_vm._v(" "+_vm._s(_vm.question.code)+" ")]):_vm._e(),_c('p',{staticClass:"question-text__question",domProps:{"innerHTML":_vm._s(_vm.decodeHtml(_vm.question.question[_vm.lang]))}}),(_vm.getFirstError(errors))?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.getFirstError(errors))+" ")]):_c('p',{staticClass:"help"},[_vm._v(_vm._s(_vm.questionHelp))])]),_c('div',{staticClass:"question-content card"},[_c('div',{staticClass:"card-content"},[(_vm.categories)?_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"category-content"},[(category.display_title && category.sub_questions.length > 0)?_c('p',{staticClass:"category-title"},[_vm._v(_vm._s(category.title[_vm.lang] || Object.values(category.title)[0]))]):_vm._e(),_vm._l((category.sub_questions),function(subQuestion){return _c('ValidationProvider',{key:subQuestion.id,staticClass:"my-3",attrs:{"rules":Object.assign({}, _vm.validationRules, (_vm.qOpts.total ? {total: { total: _vm.qOpts.total, totalValueCurrent: _vm.totalValue }}: {})),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"field is-horizontal"},[_c('div',{staticClass:"field-label is-normal",class:[_vm.isRtl ? 'text-right' : 'text-left']},[_c('label',{staticClass:"label",class:{ 'has-text-danger has-text-weight-bold': errors[0] }},[_c('div',{domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}})])]),_c('div',{staticClass:"field-body"},[_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{directives:[{name:"numeric-only",rawName:"v-numeric-only",value:(_vm.qOpts.type === 'number'),expression:"qOpts.type === 'number'"}],attrs:{"expanded":"","placeholder":_vm.$t(("multiple_input." + (_vm.qOpts.type) + "_placeholder")),"type":_vm.qOpts.type,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)],"disabled":_vm.loading,"use-html5-validation":false,"step":"any"},on:{"input":function($event){_vm.onChange(
                        _vm.questionKey(_vm.question.qid, subQuestion.code),
                        $event
                      )}}}),(_vm.qOpts.suffix)?_c('p',{staticClass:"control"},[(_vm.qOpts.suffix.config)?_c('span',{staticClass:"button is-static"},[_vm._v(_vm._s(_vm.qOpts.suffix.config[_vm.lang] || _vm.qOpts.suffix.is_enabled))]):_c('span',{staticClass:"button is-static"},[_vm._v(_vm._s(_vm.qOpts.suffix))])]):_vm._e()],1)],1)])]}}],null,true)})})],2)}):_vm._l((_vm.question.sub_questions),function(subQuestion){return _c('ValidationProvider',{key:subQuestion.id,staticClass:"my-3",attrs:{"rules":Object.assign({}, _vm.validationRules, (_vm.qOpts.total ? {total: { total: _vm.qOpts.total, totalValueCurrent: _vm.totalValue }}: {})),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('div',{staticClass:"field is-horizontal"},[_c('div',{staticClass:"field-label is-normal",class:[_vm.isRtl ? 'text-right' : 'text-left']},[_c('label',{staticClass:"label",class:{ 'has-text-danger has-text-weight-bold': errors[0] }},[_c('div',{domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}})])]),_c('div',{staticClass:"field-body"},[_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{directives:[{name:"numeric-only",rawName:"v-numeric-only",value:(_vm.qOpts.type === 'number'),expression:"qOpts.type === 'number'"}],attrs:{"expanded":"","placeholder":_vm.$t(("multiple_input." + (_vm.qOpts.type) + "_placeholder")),"type":_vm.qOpts.type,"value":_vm.value[_vm.questionKey(_vm.question.qid, subQuestion.code)],"disabled":_vm.loading,"use-html5-validation":false,"step":"any"},on:{"input":function($event){_vm.onChange(
                      _vm.questionKey(_vm.question.qid, subQuestion.code),
                      $event
                    )}}}),(_vm.qOpts.suffix)?_c('p',{staticClass:"control"},[(_vm.qOpts.suffix.config)?_c('span',{staticClass:"button is-static"},[_vm._v(_vm._s(_vm.qOpts.suffix.config[_vm.lang] || _vm.qOpts.suffix.is_enabled))]):_c('span',{staticClass:"button is-static"},[_vm._v(_vm._s(_vm.qOpts.suffix))])]):_vm._e()],1)],1)])]}}],null,true)})}),(_vm.qOpts.total && _vm.qOpts.type == 'number')?[_c('div',[_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"field is-horizontal"},[_c('div',{staticClass:"remaining-total"},[_c('div',{staticClass:"remaining-left"},[_vm._v(" "+_vm._s(_vm.$t("multiple_input.remaining"))+" ")]),_c('div',{staticClass:"remaining-right"},[_vm._v(" "+_vm._s(_vm.qOpts.total - _vm.totalValue)+" ")]),_c('div',{staticClass:"remaining-left"},[_vm._v(" "+_vm._s(_vm.$t("multiple_input.total"))+" ")]),_c('div',{staticClass:"remaining-right"},[_vm._v(" "+_vm._s(_vm.totalValue)+" ")])])])])])]:_vm._e()],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }