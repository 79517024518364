export const setLang = (lang) => {
    if (!lang) return;
    document.documentElement.setAttribute("lang", lang);
  };
  export const setRtl = (isRtl) => {
    const html = document.documentElement;
    if (!isRtl) return html.removeAttribute("dir");
    html.setAttribute("dir", "rtl");
  };

export const isRtl = () => {
  const html = document.documentElement;
  return html.getAttribute('dir') === 'rtl';
};
  